<style scoped>
.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px); /* Safari 6.0 - 9.0 */
  transition: all 0.8s;
}

.error {
  filter: grayscale(100%);
  transition: all 0.5s;
}

.dragging {
  border: 2px solid #2b736b;
  border-radius: 5px;
  background-color: #d8ffed;
}
</style>
<template>
  <container>
    <v-card>
      <input
        type="file"
        ref="file"
        @change="handleUploadFiles"
        v-show="false"
        accept="image/png, image/jpeg"
        multiple
      />
      <v-card-title>
        {{ $capitalize($tc("model.image_predict_page_title")) }}

        <v-spacer></v-spacer>
        <v-btn class="mx-1" @click="openFilesDialog" color="primary">
          <v-icon size="24" left>mdi-upload</v-icon>
          {{ $capitalize($tc("model.image_predict_upload_button")) }}
        </v-btn>
      </v-card-title>
      <v-card-text :class="{ dragging: dragging }">
        <drop
          drop-effect="move"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
          @drop="handleDrop"
        >
          <empty-state
            :when="images.length == 0"
            :message="
              $capitalize($tc('model.image_predict_empty_state_message'))
            "
            height="400"
          >
            <v-virtual-scroll
              :bench="1"
              :items="chunked_images"
              :item-height="lineHeight"
              :height="virtualScrollerHeight"
              v-resize="onResize"
            >
              <template v-slot="{ item }">
                <v-row dense no-gutters>
                  <v-col
                    v-for="image in item"
                    :key="image.id"
                    class="d-flex child-flex px-1"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <v-card>
                      <v-img
                        aspect-ratio="1"
                        :src="image.content"
                        :class="{
                          blur: isImageLoading(image),
                          error: image.error,
                        }"
                        :height="imageHeight"
                        :gradient="`to bottom, rgba(0,0,0,.0), rgba(0,0,0,.60)`"
                        @click="openImagePreview(image)"
                        style="cursor: pointer"
                      >
                        <v-row
                          align="end"
                          class="lightbox white--text pa-2 fill-height"
                          v-if="!image.error"
                        >
                          <v-col>
                            <div class="body-1">{{ image.description }}</div>
                            <div class="subheading">
                              {{
                                $capitalize(
                                  $tc("model.image_predict_precision")
                                )
                              }}: {{ image.precision }}%
                            </div>
                          </v-col>
                        </v-row>
                      </v-img>
                      <v-card-actions>
                        <v-btn
                          icon
                          :loading="isImageLoading(image)"
                          @click="refreshImage(image)"
                          ><v-icon>mdi-refresh</v-icon></v-btn
                        >
                        <v-btn
                          icon
                          :disabled="isImageLoading(image)"
                          @click="confirmImageDelete(image)"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                        <v-btn icon v-if="image.error"
                          ><v-icon color="red" title="Erro"
                            >mdi-alert</v-icon
                          ></v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-virtual-scroll>
          </empty-state>
        </drop>
      </v-card-text>

      <confirm-action-dialog
        :confirm_action="delete_image_confirmation"
        @change="delete_image_confirmation = $event"
        @action="deleteImage()"
        :message="
          $capitalize(
            $tc('model.image_predict_image_remove_confirmation_message')
          )
        "
      ></confirm-action-dialog>

      <v-dialog
        v-model="preview_dialog"
        @click:outside="preview_dialog = false"
        max-width="700px"
        overlay-opacity=".9"
      >
        <v-card dark v-if="preview_image">
          <v-card-title>
            <v-icon size="23" left>mdi-file-image</v-icon>
            {{ preview_image.description }}
            <v-spacer />
            <v-btn @click="downloadImage()" icon>
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn @click="preview_dialog = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-img contain max-height="600" :src="preview_image.content"></v-img>
        </v-card>
      </v-dialog>
    </v-card>
  </container>
</template>

<script>
import ConfirmActionDialog from "@/components/ConfirmActionDialog";
import EmptyState from "@/components/EmptyState";

export default {
  components: {
    ConfirmActionDialog,
    EmptyState,
  },
  data: () => ({
    windowHeight: 0,
    dragging: false,
    delete_image_confirmation: false,
    deleted_image: null,
    preview_dialog: false,
    preview_image: null,
    images: [],
    loadingImages: [],
  }),
  methods: {
    delay(delay) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    },
    async handleUploadFiles($event) {
      try {
        for (let file of $event.target.files) {
          const response = await this.uploadImage(file);
        }
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: "Não foi possível adicionar algumas imagens",
          color: "red",
        });
      }
    },
    async handleDrop(data, event) {
      if (event.dataTransfer) {
        try {
          for (let file of event.dataTransfer.files) {
            const response = await this.uploadImage(file);
          }
        } catch (e) {
          this.$store.commit("sendMessage", {
            text: "Não foi possível adicionar algumas imagens",
            color: "red",
          });
        }
      }
    },
    openFilesDialog() {
      this.$refs.file.click();
    },
    openImagePreview(image) {
      this.preview_dialog = true;
      this.preview_image = image;
    },
    downloadImage() {
      window.open(this.preview_image.content);
    },
    isImageLoading(image) {
      return this.loadingImages.some(
        (loadingImageId) => loadingImageId == image.id
      );
    },
    async uploadImage(file) {
      var image = {
        content: this.getUrlPreview(file),
        file,
        ...this.generateFakeImageData(file),
      };

      this.images.push(image);
      this.addLoading(image.id);
      await this.delay(1000);
      this.removeLoading(image.id);
      this.dragging = false;
    },
    getUrlPreview(image) {
      return URL.createObjectURL(image);
    },
    async refreshImage(image) {
      this.addLoading(image.id);
      await this.delay(1000);
      this.removeLoading(image.id);
    },
    addLoading(id) {
      let find = false;
      this.loadingImages.forEach((loadingImageId) => {
        if (loadingImageId == id) find = true;
      });

      if (find == false) {
        this.loadingImages.push(id);
      }
    },
    removeLoading(id) {
      this.loadingImages = this.loadingImages.filter(
        (loadingImageId) => loadingImageId !== id
      );
    },
    confirmImageDelete(image) {
      this.delete_image_confirmation = true;
      this.deleted_image = image;
    },
    deleteImage() {
      this.removeLoading(this.deleted_image.id);
      this.images = this.images.filter(
        (image) => image.id !== this.deleted_image.id
      );
      this.delete_image_confirmation = false;
      this.deleted_image = null;
    },
    // mockup functions
    generateId(len) {
      var arr = new Uint8Array((len || 40) / 2);
      window.crypto.getRandomValues(arr);
      return Array.from(arr, this.dec2hex).join("");
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    generateFakeImageData(file) {
      const randomInteger = this.getRndInteger(0, 8);
      const fake_image = this.fakeData.find((data) =>
        file.name.startsWith(data.name)
      );

      return {
        id: this.generateId(),
        ...fake_image,
      };
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  computed: {
    lineHeight: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "260";
        case "sm":
          return "210";
        case "md":
          return "160";
        case "lg":
          return "260";
        case "xl":
          return "260";
      }

      return "250";
    },
    itemsPerLine: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
        case "md":
          return 3;
        case "lg":
          return 4;
        case "xl":
          return 4;
      }

      return 6;
    },
    imageHeight: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200";
        case "sm":
          return "150";
        case "md":
          return "100";
        case "lg":
          return "200";
        case "xl":
          return "200";
      }

      return "200";
    },
    chunked_images: function () {
      const groupsOfImages = [];

      for (let i = 0; i < this.images.length; i += this.itemsPerLine) {
        groupsOfImages.push(this.images.slice(i, i + this.itemsPerLine));
      }

      return groupsOfImages;
    },
    virtualScrollerHeight: function () {
      return this.windowHeight * 0.7;
    },
    fakeData: function () {
      return [
        {
          name: "SWyUSDZSoN48uY3Hd50wkPpLfe7NLeIOA7PhrFmw",
          description: this.$capitalize(this.$tc("model.room_type_kitchen")),
          precision: 97,
        },
        {
          name: "WdbThuQhR2aw6OjZU8tw6IlIl04mG7mkGezOmzOd",
          description: this.$capitalize(this.$tc("model.room_type_wc")),
          precision: 88,
        },
        {
          name: "n4nnwsEnNrvNeLNaJoXpyEznKS3FJoK989fUIv4W",
          description: this.$capitalize(this.$tc("model.room_type_bedroom")),
          precision: 98,
        },
        {
          name: "flw3Q8JPlY3ON9sVq9J4IwAxo3SxIcqAlPc3LQPo",
          description: this.$capitalize(this.$tc("model.room_type_room")),
          precision: 85,
        },
        {
          name: "Z7tHf26Ps3o92qNz4p4ZiHjVqb5Uw7ucY5xrwS86",
          description: this.$capitalize(this.$tc("model.room_type_office")),
          precision: 93,
        },
        {
          name: "YnD176RsNtmWlb66ata3DZlvPCBx2cNCmcUxEnmS",
          description: this.$capitalize(this.$tc("model.room_type_facade")),
          precision: 99,
        },
        {
          name: "XveoMoVhMfDXQSvAyILaQ380sZwGLL3elbYtU6na",
          description: this.$capitalize(this.$tc("model.room_type_basement")),
          precision: 81,
        },
        {
          name: "XSa95NvC22kzkAVhZkhQz2kfhVEv8C9vbC3U7RUk",
          description: this.$capitalize(this.$tc("model.room_type_courtyard")),
          precision: 76,
        },
        {
          name: "xoxSLrORTRhJF3RuWenpgs76Uipp37WrxLVlaYd7",
          description: this.$capitalize(this.$tc("model.room_type_kitchen")),
          precision: 87,
        },
        {
          name: "EiMQlbkaPi7nSiKHXjornOHzJpveMIQ78WUDQzDr",
          description: this.$capitalize(this.$tc("model.room_type_wc")),
          precision: 99,
        },
        {
          name: "d2b122e3-76a3-4cbc-909b-5c28343ab3d2",
          description: this.$capitalize(this.$tc("model.room_type_room")),
          precision: 97,
        },
        {
          name: "ikDumeBCxNHW4M5ZPLPtPgMWwnTcGX2RhEFmZjnG",
          description: this.$capitalize(this.$tc("model.room_type_bedroom")),
          precision: 96,
        },
      ];
    },
  },
  mounted() {
    this.onResize();
  },
};
</script>
